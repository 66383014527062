(function ($, undefined) {
    "use strict";
    var leftMenuLIs = [];

    function drop_vm_actins_apply() {
        return drop_vm_actins(true);
    }

    function drop_vm_actins() {
        var menu = $('#vm-menu'),
            drop = [],
            avb_num = 4; //max 4, otherwise icon

        if(menu.length === 0){
            $('<div id="lockable-vm-menu"><ul id="vm-menu"></ul></div>').prependTo('#content-cloud');
            menu = $('#vm-menu')
        }

        if (!menu.data('parsed') && $('.section-main').hasClass('service-layout-top')) {
            menu.data('parsed', true)
            if ($.isEmptyObject(leftMenuLIs)) {
                $('.cloud .cloud-top .cloud-nav ul li').each(function () {
                    leftMenuLIs.push($(this).prop('outerHTML'));
                });
                $('.cloud .cloud-top .cloud-nav').remove();
            }

            leftMenuLIs.forEach(function (item) {
                menu.append(item);
            });
        }

        var items = menu.data('items') || menu.children('li'),
            avb_width = menu.width() - 60;


        menu.data('items', items);
        menu.append(items);
        menu.find('.vm-menu-dropdown').remove();


        if (window.toggleVmActionDrop !== undefined && window.toggleVmActionDrop === false) {
            return;
        }

        items.each(function () {
            var self = $(this),
                width = self.width();

            if (avb_num < 1 || avb_width - width < 0 || width < 50) {
                drop.push(self.detach());
            } else {
                avb_width -= width;
                avb_num -= 1
            }
        });

        if (!drop.length) {
            return true;
        }

        var dropdown = $('<li class="vm-menu-dropdown">' +
            '<a href="#" class="dropdown-toggle" data-toggle="dropdown" data-flip="false" href="#" aria-expanded="true">' + jsLang.more + '</a>' +
            '<ul class="dropdown-menu dropdown-menu-right"></ul>' +
            '</li>');

        dropdown.find('.dropdown-menu').append(drop);
        menu.append(dropdown);
        menu.css({visibility: "visible"});
    };

    function debounce(func) {
        var timer;
        return function (event) {
            if (timer) clearTimeout(timer);
            timer = setTimeout(func, 100, event);
        };
    }

    $(window).on('resize', debounce(drop_vm_actins));

    $(document).on('ready', drop_vm_actins_apply);
    $(document).on('cloud.loaded.actions', drop_vm_actins_apply);
    $(document).on('ajaxComplete', drop_vm_actins);

})(jQuery);