(function ($, undefined) {
    "use strict";

    /*
     * updates bootstrap2 modals to bootstrap4 format
     */

    var modalProxy = $.fn.modal,
        mv_dialog_class = ['modal-dialog-centered', 'modal-sm', 'modal-lg', 'modal-xl'];

    var modalOwerride = function () {
        var modal = $(this);

        var dialog = modal.find('.modal-dialog');
        if (dialog.length === 0) {
            modal.wrapInner('<div class="modal-dialog" role="document"></div>');
            dialog = modal.find('.modal-dialog');

            $.each(mv_dialog_class, function (i, mvclass) {
                if(modal.hasClass(mvclass)){
                    modal.removeClass(mvclass);
                    dialog.addClass(mvclass);
                }
            })
        }

        if (dialog.find('.modal-content').length === 0) {
            dialog.wrapInner('<div class="modal-content"></div>');
        }

        var close = dialog.find('.modal-header .close');
        if (close.length) {
            close.html('<i class="material-icons">cancel</i>');
            close.parent().append(close); //move it to be last child
        } else {
            dialog.find('.modal-header').append(
                '<button type="button" class="close" data-dismiss="modal" aria-label="Close">\n' +
                '<i class="material-icons">cancel</i>\n' +
                '</button>'
            )
        }

        dialog.find('.modal-header h1, .modal-header h2, .modal-header h3, .modal-header h3').each(function () {
            $(this).replaceWith('<h4 class="modal-title font-weight-bold mt-2">' + this.innerHTML + '</h4>');
            return false; //just the first one
        });

        dialog.find('.control-group').each(function () {
            var group = $(this);
            this.classList.add('form-group');

            $('.controls input, .controls textarea, .controls select', this).each(function (i, formelm) {
                if (formelm.getAttribute('type') === 'checkbox') {
                    group.prepend(this);
                    return;
                }
                if (formelm.getAttribute('type') === 'radio') {
                    return;
                }

                formelm.classList.add('form-control');
            })
        });

        modal.on('show.bs.modal shown.bs.modal hide.bs.modal hidden.bs.modal', function(e){
            if(e.namespace === 'bs.modal'){
                modal.trigger(e.type)
            }
        })

        return modalProxy.apply(this, arguments);
    };

    $.fn.modal = $.fn.modal.Constructor._jQueryInterface = modalOwerride;

})(jQuery);

(function ($, undefined) {
    "use strict";
    /*
    * adds fixTitle option from bootstrap2
    */

    var tooltipProxy = $.fn.tooltip;

    $.fn.tooltip = function (options) {
        var returnValue = this;

        if (typeof options === "string") {
            if (options === 'fixTitle') {
                options = 'update';
                this.each(function () {
                    this.setAttribute('data-original-title', this.getAttribute('title'));
                });
            }
            return tooltipProxy.apply(this, [options]);
        }

        this.each(function () {
            var self = $(this);

            tooltipProxy.apply(self, arguments);
        });

        return returnValue;
    }
})(jQuery);

(function ($, undefined) {
    "use strict";

    /*
    * used to reformat sliders and remove inline css (width, margin, float)
    */
    var proxySlider = $.fn.slider;

    $.fn.slider = function (options) {
        var params = arguments;

        if (typeof options === "string") {
            return proxySlider.apply(this, arguments);
        }

        this.each(function () {
            var self = $(this);

            if (self.data('uiSlider')) {
                return proxySlider.apply(self, params);
            }

            var slider = proxySlider.apply(self, params);

            slider.data('slider', slider.data('uiSlider'));
            self.css({
                float: '',
                width: '',
                margin: '',
            });

            var container = self.closest('.input-with-slider'), slabel;
            if (container.length) {
                container.addClass('slider-group');
                if (!container.find('.slider-value').length) {
                    container.wrapInner('<div class="slider-value"></div>');
                    container.append(self);
                }
                slabel = container.find('.slabel');
                if (slabel.length) {
                    slabel.addClass('for-slider')
                        .insertBefore(container);
                }
            }

            return slider;
        });

        return this;
    }
})(jQuery);

(function ($, undefined) {
    "use strict";

    /*
    * update dropdown menu from v2 to v4 format
    */

    var proxy = $.fn.dropdown.Constructor._jQueryInterface;
    $.fn.dropdown.Constructor._jQueryInterface = function(e, data){
        this.each(function () {
            $(this).siblings('.dropdown-menu').each(function(){
                var menu = $(this);

                if(menu.parents('.pull-right').length){
                    menu.addClass('dropdown-menu-right');
                }

                if(menu.find('.dropdown-item').length){
                    return;
                }

                menu.find('li > a').addClass('dropdown-item');
            });
        });

        return proxy.apply(this, arguments);
    }
})(jQuery);